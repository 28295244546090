.boxForFooter{
    position:relative;
}
footer {
  min-height: 300px;
  width:100%;
  background-color: $black--two;

  position: relative;
  
  .footSec1 {
    min-height: 280px;
    width: auto;
    color: $grey--two;
    font-size:1em;
     font-weight: 450;
     
     padding: 10px 0;
     .footer-container{
         display:flex;
         margin:auto;

         width:100%;
         div{
             width:calc(100% / 2.5);
         }
     }
     li{
         margin:10px 0;
     }
     .footer-link-wrap{
         margin: 10px;
          text-align:left;
     }
     .footer-links{
         color:$grey--one;
         padding:5px;
        
     }
  }
  .footSec2 {
    color: $grey--one;
    text-align: center;
    font-weight: 450;
     font-size:1em;
     height:80px;
  }
}
