// colors
$white: #ffffff;
$black: #000000;
$black--two:#333333;
$black--three: #212428;
$red: #d11402;
$green: #008000;
$dodgerblue:#1e90ff;

$grey--one: #ececec;
$grey--two: #aaaaaa;
$grey--three: #929292;
$grey--four: #cbcbcb;
$grey--five: #a8a8a8;
$grey--six: #c6c6c6;
$grey--seven: #dddddd;
$grey--eight:#f5f5f5;

$blue: #4a44f2;
$blue--two: #eeeeff;
$purple--three: #722adf;
$purple--self:#800080;