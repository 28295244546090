.h-top-2{
	.f1{
		padding-top:25px;
	}
	
}
.t1{
	//margin-top:50px;
	td{
		padding:5px;
	}
}
.t2{
	min-width: 100%;
	td{
		padding:5px;
	}
}





.pdf-template{
		font-family:italic;
		width:700px;
		

		box-sizing:border-box;
		background-color:#fff;
		padding:40px 40px 0;
		margin:auto;
		//commented margin-top to avoid page from doubling on 6/6/21
		//margin-top:2%;
		padding-top:60px;
/*
		background-image:url("../../images/hjo.jpg");
		background-size:100%;
		background-repeat:no-repeat;*/
	}
		.pdf-headers{
		width:90%;
		height:100px;
		margin:auto;
		margin-bottom:30px;
		margin-top:20%;
		.pdf-headers-chd{
			display:flex;
			height:60px;
			div{

			}
		}
	}
	.header-fan-brv{
		width:40%;
		text-align:right;
		padding:10px;
		padding-right:10px;
		div{
			padding:8px 10px;
		}
	}
	.pdf-content-wrapper{
		
	}
	.hdr-bdc-omc{
		width:60%;
		text-align:center !important;
		padding:10px;
		display:flex;
		align-items:center;
		font-size:1.5em;
		span{
			width:100%;
			text-align:center !important;
			font-size:1.5em;
		}
	}
	.key-light{
		color:rgb(26, 25, 25);
	}
	.pdf-tbl{
		font-size:1.1em;
		//margin-top:10px;
		overflow:hidden;
		tbody{
			margin-bottom:30px;
		}
		th{
			padding:5px;
			font-size:.8em;
		}
		td{
			padding:10px;
			font-size:.9em;
		}
	}
	.pdf-td{
		width:calc(100% / 5);
		text-align: center;
		padding:5px 10px;
	}
	.pos-right{
		text-align: right;
		padding:10px;
		div{
			padding:10px;
		}
	}
	.top-tbody{
		height:380px;
		width:50%;
		
	}
	.destination-box{
		padding:10px;
		text-align:center;
		margin-top:10px;
		margin-right:50px;
		//margin-bottom:5px;
		font-size:2.2em !important;
		strong{

		}
	}
	.issuer-name{
		font-size:1.2em !important;
		display:flex;
		width:100%;
		margin-top: 5px;
		div{
			font-size:1.1em;
			width:calc(100% / 4);
			font-size:.8em !important;
			padding-top:10px;
			vertical-align:bottom;
			padding-left:25px;
		}
	}
	tbody{
		th,td{
			white-space: nowrap;
  			overflow: hidden;
		}
	}
	.btm-tbody{
		td{padding:5px;}
	}
	.tbody-pdf-info{
		td{
			padding:5px;
		}
	}
	.tbl-pdf-v1{
		font-weight:bold;
		margin-top:60px;
		font-size:0.9em;
		tbody{
			width:calc(100% / 3);
			margin:10px;
		}
		td{
		font-size:.9em;
		padding:5px;
		}
	}