.t-tag {
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  color: $black--two;
  text-align: center;
  margin: 0;
  span {
   
    font-weight:400;
    font-size:.7em;
    border:2px solid $grey--one;
    padding: 2px 10px;
    text-transform:capitalize;

  }
}
.slide-element-box{
  height:350px;
}
.description-tbl{
  font-size:1.2em;
color:lighten($black,30%);
}
.item-bold{
  font-weight:500;
 color:$black--two;
}
.item-desc-main{
  margin:50px 0;
  background-color:$white;
  padding:20px 10px;
  border-top-right-radius:20px;
    border-top-left-radius:20px;

}
.itm-sidebar-info{
  
  width:30vw;
  height:100vh;
  margin:0 10px;

    .itm-sb-container{
      
    .sb-md{
      margin:10px 0 0 0 !important;
      min-height:50vh !important;
    }
      .itm-sb-chd{
        //border:1px solid $grey--seven;
        background-color:$white;
        min-height:15vh;
        margin:0 0 5px;
        padding:10px;

        img{
          height:20px;
          width:20px;
        }
        .slr-address {
          text-decoration:none;
         margin:10px 0;
          label{
              vertical-align: top;
              margin-left:10px;
              font-weight:480;
              font-size:.8em;
             
        }
      }




      }
    }
}
.slr-info-element{
  margin:20px 0;
  display:flex;
}
.logo-box{
  height:60px;
  width:60px;
  margin-right:10px;
 display:flex;
 align-content:center;
  img{
    height:50px !important;
    width:50px !important;
     border-radius:50%;
     border:1px solid $grey--seven;
     padding:5px;
     box-shadow:1px 2px 4px $grey--one;
  }
}
.item-layout{
  width:70% !important;
  height:auto;
  background-color:$white;
}