$button--primary:blue;
$button--outline:$grey--three;
.button{
    height:40px;
    min-width:80px;
    border-radius: 0;
    padding: 10px;
    background-color: $white;
    cursor: pointer;
    //transition: all .4s ease-out;
    &:focus{
        outline: none;
    }
}

.btn-border-radius{

}
.btn--primary{

}
.btn-default{
    background-color: $purple--three;
    border:0;
    color: $white;
    font-weight: 500;
}
.btn--outline{

}

.sign-up-btn{
    width:100%;
    height:50px !important;
    font-size:1em;
    font-weight:500;
    border-radius:2px;
    margin-top:2px !important;
    border:1px solid darken($purple--three,10%)  !important;
}