.kGtr6{
	display:flex;
		.filter-sec{
			width:250px;
			margin-right:10px;
		}
		.ads-sec{
			width:100%; 
		}

	@include for-mobiles-only{
		display:block;
		.filter-sec{
			width:100%;
			margin-right:10px;

			background-color:$white;
		}
		.ads-sec{
			width:100%;
		}
	}
}

.filter-for-larger-screens{
	display:block;
	margin-top:10px;
	@include for-mobiles-only{
		display:none;
	}
}