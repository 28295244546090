.other-section {
  min-height: 600px;
  margin: 50px 0;
  padding: 50px 0;
  background-color: $grey--eight;
h1{
    height:50px;
    color:$black--two;
    text-align: center;
}
.sort-cars{
    display: flex;
    justify-content: center;
    margin:30px 0;
    button{
        border:1px solid $grey--three;
       width:100px;
       padding:5px 0;
       font-weight:500;
        // background-color: $purple--self;
    }
}
}
.car-brand-logo-wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .brand-item {
   @include for-mobiles-only{
      
        margin: 8px;
    }
    margin: 10px;
    img {
      width: 90px;
      height: 90px;
      background-color: $white;
       @include for-mobiles-only{
        width:70px;
        height:70px;
    }
    }

  }
  
}
.round-hero{
  width:50px;
  height:50px;
  border-radius:9999%;
  background-color:$white;
  display:flex;
  align-content:center;

}
.section-cards{
     width:80% !important;
     margin:auto !important;
      @include for-mobiles-only{
        width:98% !important;
    }
  }
.layout{
  width:80% !important;
    margin:auto;
    @include for-mobiles-only{
        width:98% !important;
    }
}