* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  background-color: $primary--color;
  height: 100vh;
  width: 100%;
}
.a {
  text-decoration: none;
}
.ul-group {
  list-style: none;
}
.page-container {
  min-height: 100vh;
  width: 100%;
  padding: 10px;
  background-color:$grey--eight;
}
.wrapper {
  padding: 10px;
}
.flex {
  display: flex;
}
.checkbox-label {
  margin: 10px;
  text-transform:capitalize;
}
.options-list {
  margin-bottom: 20px;

  li {
    padding: 5px;
  }
}
.input-group {
  height: auto;
  margin: 5px;
  display: block;
  width: 310px;

  border: 1px solid $grey--three;
  label {
    position: absolute;
    font-size: 0.85em;
    color: $black--three;
    padding: 0 5px;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 5px;
    margin-top: 10px;
    border:0;
    &:focus{
        outline: none;
    }
  }
}
.form-group{
    margin: auto;
}
.no-border{
  border:0;
}
.description-tbl{
  width:100%;
  height:auto;
  margin-top:20px;
   th{
     color:$black--two;
     text-align:left;
   }
}
.space-item-section{
  min-height:300px;
}
.listings-box{
  padding:0 !important;
}
.itm-tag{
   background-color:$grey--eight;
 padding:2px 10px;
  margin-right:5px;
  vertical-align:-moz-middle-with-baseline;
  vertical-align:middle;
}
.cover-itm-tag{
  width:auto;
  height:30px;
  margin-top:10px;
}
.dUdj5{}
[list]::-webkit-calender-picker-indicator{
    display:none;
  }
