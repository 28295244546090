//0606bb12 light blue input
//0659bbf0 button
//000000c8 lbls form
//00000017 border color
//0000009f hint lbl

//variables
@import 'toggleSwitch';
@import 'pdf-template';
@import 'base';
$input-background: #0606bb12;
$submit-button: #0659bbf0;
$form-label-color: #000000c8;
$grey-one: #00000017;
$grey-two: #0000009f;
$grey-three: #f5f5f5;

.depot-name-container{
	position:relative;
	
}
.btn-logout{
	//border:0;
	color:royalblue;
	border:1px solid royalblue !important;
	padding:5px 10px;
	cursor:pointer;
}
//nav custom
.depot-type{
	height:25px;
	width:25px;
	border-radius:10px;
	color:#333;
	background-color:transparent;
	
	//position:absolute;
	padding:0 10px;
	font-size: .7em;
	margin:auto;
	::after{
		content:"APD";
		width:40px;
		height:20px;
		margin-left:130px;
		position:absolute;
		background-image:linear-gradient(to right,#ffa60066,rgba(248, 210, 138, 0.354));
		border-radius:5px;
	}
	span{
		display: flex;
		justify-content: center;
	}
}

//form
.form-grp {
	width: 750px;
	min-height: 300px;
	border: 1px solid $grey-one;
	margin: auto;
	margin-top: 40px;
	border-radius: 5px;
}
.fm-main-field {
	display: flex;
	width: 720px;
	justify-content:center;
	margin: auto;
	margin-top: 30px;
}
.fm-part-group {
	display: block;
	width: 180px;
}
.tblEl{
	width:715px !important;
	overflow-x:scroll;
	margin:auto !important;
	
}
.hdrQ2{
	width:720px;
	margin:auto;
	font-weight:500;
	margin-top:20px;
	margin-bottom:20px;
	padding:0 5px;
	padding:10px;
	border-bottom:1px solid #8f8f8f21;
}
.fieldEl {
	width: 170px;
	background-color: $input-background;
	height: auto;
	margin: 2px;
	padding: 5px 10px;
	border-radius: 2px;
	font-size: 0.8em;
	overflow: hidden;

	input,
	select {
		width: 150px;
		height: 25px;
		border: 1px solid $grey-one;
		padding: 5px;
		margin-top:2px;
		border-radius: 5px;
		font-size: .9em;
		color: #333;
		font-weight: 500;
		&:focus {
			outline: none;
		}
	}
}
.splitEl {
	border:1px solid rgb(148, 148, 148);
	display: flex;
	margin-bottom: 5px !important;
	input {
		width: 100%;
		margin-right:10 !important;
		border-radius:1px !important;
	}
	.comp {
		margin-right: 5px;
	}
	.dynBoxCover{
		margin-right:5px !important,
	}
}
.submitBtn {
	height: 40px;
	width: 200px;
	background-color: $submit-button;
	color: #fff;
	border: 0;
	border-radius: 5px;
	font-weight: 500;
	z-index:100;
	cursor: pointer;
	margin: 5px;
	&:focus {
		outline: none;
	}
}
.hint-label {
	color: $grey-two;
	font-size: 0.8em;
}
.yuOp2 {
	.submitBtn {
	position: fixed;
		width: 50px;
		height: 50px;
		font-size:.7em;
		border-radius: 50%;
		margin: 10px 5px;
		bottom: 20px;
		right: 20px;
		z-index:3;
		box-shadow: 1px 2px #ddd;
	}
}
.hint-btn {
	border-radius: 50%;
	width: 25px;
	margin: 0 10px 0 0;
	height: 25px;
	font-weight: 500;
	font-size: 1.5em;
	border: 1px solid $grey-one;
}

// DASHBOARD STYLE
.pane {
	display: flex;
	position:fixed;
	overflow:hidden;
	width:100vw;
	top:50px;
	height:calc(100vh);
}
.curr {
	background-color: $input-background;
}
.sb-main1 {
	//position:sticky;
	background: $sidebar-background;

}
.info{
	
}
.jkuyi8{
	margin-bottom:10px !important;
}
.user-panel{
	border-bottom:1px solid #4b545c;
}
.sidebar-container {
	width: 200px !important;
	height: 100vh;
	border-right: 1px solid $grey-one;
	background-color:$sidebar-background;
	//box-shadow: 1px 2px 12px #ccc;
	li {
		height: 40px;
		width: 100%;
		&:hover {
			background-color: lighten($input-background, 50%);
		}
		.link-itm {
			padding: 10px;
			display: flex;
			vertical-align: middle;
			color:#f5f5f5;
			//background-color: #fff;
			img {
				margin-right: 10px;
				box-shadow: 1px 2px 3px #ddd;
				padding: 3px;
				border-radius: 3px;
			}
		}
		a {
			text-decoration: none;
			color: #333;
			font-weight: 480;
		}
	}
}
.main-bost-pg {
	width: 100%;
	padding: 10px;

	//margin-left:180px;
}
.tbl-wrap2{
	//display:none;
	background-color: #fff;
    position: fixed;
    overflow: scroll;
	max-height: 60vh;
	max-width:calc(100vw - 200px); 
	//z-index: -3;
	margin-top:10px;

}
.bost-tbl {
	min-width: 70vw;
	text-align: left;
	th {
		font-weight: 500;
		height:30px;
		background-image:linear-gradient(#222B47,#222B47);
		color:#fff;
		padding:0 10px;
	}
	td {
		padding: 5px;
	}
	tr:nth-child(even) {
		background-color: $grey-three;
	}
}
.nav-back {
	height: 2.5em !important;
	width: 2.5em;
	//background: $grey-three;
	border-radius: 50%;
	color: #333;
	margin: 10px;
}
.err-container {
	width: 500px;
	margin: auto;
}
.err-box {
	color: red;
	text-align: left;
}
.sticky-btm-row{
	position: -webkit-sticky;
	position:sticky;
	bottom:0;
	background-color:#fff;
	text-align:left;
}
.sticky{
	position: -webkit-sticky;
	position:sticky;
	top:0;
	
	text-align:center;
}
.container{
	background-color: #fff !important;
	font-family: italic;
	min-height:100vh;
}
.pdf-templater{
	font-family: italic;
	//overflow:hidden;
	box-sizing:border-box;
	color:#000 !important;
	margin:auto;
	
	display:flex;
	padding-top:20%;
}
.TjkG3 {
	//background-color: $grey-three;

	height:auto;
	
}

.coll_order_date {
	display: flex;
	justify-content: space-around;
	align-content: center;
	div {
		text-align: right;
		width: 50%;
		//padding-top: 10px;
		padding-right: 30px;
	}
}
.sdUtd3 {
	text-align:center;
	td{
		padding:3px;
	}
	justify-content: stretch;
	span {
		width: 110px;
		margin:0 5px;
	}
}
.yuYio0 {
	display: flex;
	justify-content: space-around;
}
.filter-wrap1{
	background-color:#fff;
	//position: relative;
	height: 80px;
	
}
.filter_form {
	background-color:#fff;
	margin-bottom: 30px;
	position: fixed;
	.fieldEl {
		width: 150px;
		padding-top: 0;
		label {
			font-size: 0.8em;
		}
		input,
		select {
			width: 130px;
			font-size: 1em;
		}
	}
}
.filter_btn_5 {
	input {
		width: 80px !important;
	}
	.fieldEl {
		background: inherit;
		vertical-align: middle !important;
		width: 80px;
		padding: 0;
		input {
			margin-top: 30px;
		}
	}
}

//error nhandling
.msg-err {
	min-height: 50px;

	padding: 10px;
	width: 280px;
	position: fixed;
	right: 10px;
	border-radius: 10px;
	background-color: red;
	color: #fff;
	z-index: 100;

	div {
		font-size: 0.8em;
	}
	button {
		font-weight: 500;
		color: #fff;
		border: 0;
		font-size: 1.5em;
		position: absolute;
		right: 10px;
		background-color: inherit;
		margin-top: -10px;
	}
}
.btn-logout {
	border: 0;
	background-color: inherit;
	text-align: left;
	&:focus {
		outline: none;
	}
}
.wrapForPrint {
	width: 700px;
	//min-height: 100vh;
	height:auto;
	background: #fff;
	margin: auto;
	//padding-bottom: 100px;
}
.fdoDnY7 {
	height: 100vh;
	width: 100%;
	//display: flex;
	justify-content: center;
	//align-items:center;
}
.dashboard-preview {
	display: flex;
	width: calc(100% - 200px);
	height: auto;

	margin: 50px;
	display: flex;

	justify-content: center;
	div {
		cursor: pointer;
		a {
			color: rgb(241, 237, 237);
			text-decoration: none;
		}
		width: 350px;
		height: 250px;

		box-shadow: rgb(28 39 43 / 20%) 0px 5px 8px;
		border-radius: 20px;
		margin: 10px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		h3 {
			// background-color: rgba(255, 255, 255, 0.3);
			// height: 150px;
			// width: 250px;
			// backdrop-filter: blur(40px);
			// text-align: center;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// border-radius: 10px;
			// border: 1px solid transparent;
			// background-clip: padding-box;
			//background-color: #fff;
		}
	}
	.UIbT1 {
		color: #fff;
		background-image: linear-gradient(rgba(royalblue, 0.8), indigo);
	}
	.UIbT2 {
		background-image: linear-gradient(rgba(tomato, 0.8), rgba(purple, 0.4));
	}
}
.login-fm {
	min-height: 200px;
	width: 300px;
	
	.fm-part-group {
		width:100% !important;
	}
	.yuOp2{
	.submitBtn {
		position: revert;
		width: 290px;
		height: 40px;
		border-radius: 5px;
		margin: 10px 5px;
		bottom: 20px;
		font-size:.8em;
		font-weight:550;
		right: 0px;
		//z-index:3;
		box-shadow: 1px 2px #ddd;
	}}
	.fieldEl{
		width:98.5%;
		text-transform:capitalize;
		input{
			width:100%;
			height:30px;
		}
	}
}

.prompt {
	left: 0;
	min-height: 100vh;
	width: 100vw;
	background-color: rgba(44, 43, 43, 0.8);
	position: fixed;
	z-index: 1000;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.confirm {
		height: 100px;
		width: 250px;
		border-radius: 10px;
		background: #fff;
		text-align: center;
	}
	.btns2 {
		position: absolute;
	}
	p {
		position: absolute;
		text-align: center;
		width: 250px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	button {
		width: calc(250px / 2);
		height: 30px;
		border: 1px solid #ddd;
		margin-top: 70px;
	}
	.pos {
		color: green;
		border-bottom-left-radius: 10px;
	}
	.neg {
		color: red;
		border-bottom-right-radius: 10px;
	}
	.confirm {
		width: 250px !important;
		animation-duration: 0.5s;
		animation-name: slidein;
	}
}
.delete-waybill-btn {
	color: red;
	float: right;
	border: 1px solid red;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	position: fixed;
	bottom: 30px;
	right: 90px;
	font-weight: 500;
	font-size: 2em;
	cursor: pointer;
}
.to-excel-btn {
	float: right;
	height: 40px;
	width: auto;
	background-color: #ddd;
	border: 0;
	a {
		color: #333;
		text-decoration: none;
	}
	img {
		height: 30px;
	}
}
//bdc-omc form styles
.hjOpfT2 {
	.prompt{
		z-index:10 !important;
	}
	.confirm {
		margin: auto;
		height: 300px;
		button {
			margin-top: 0px;
		}
	}
	.fm-main-field {
		width: auto;
	}
	.fm-part-group {
		margin: auto;
	}

}
.typEg1 {
	height: 30px;
	border: 0;
	background-color: transparent;
	padding: 5px 10px;
	//color: midnightblue;
	
}
.close3 {
	font-weight: 500;
	font-size: 2em;
	color: red;
	position: fixed;
	background-color: transparent;
	border: 0 !important;
	right: 100px;
	margin-top: -50px !important;
	z-index: 15;
}
.delete-row-btn {
	width: 40px;
	border: darken(red, 30%);
	background-image: linear-gradient(red, darken(red, 30%));
	height: 40px;
	color: #fff;
	border-radius: 50%;
	margin-right: 20px;
	position: fixed;
	font-weight: 500;
	font-size: 2em;
	right: 70px;
	z-index:100;
	bottom: 20px;
	cursor: pointer;
}
.gh6Ty {
	background-color:#f5f5f5;
	
}
.erTf {
	padding: 10px;
	height: 150px;
	overflow-y: scroll;
	overflow-x: hidden;

	.bost-tbl {
		min-width: 230px !important;
		tr,
		td {
			width: 230px;
		}
	}
}
//anime
@keyframes slidein {
	from {
		margin-bottom: 100%;
		width: 300%;
	}

	to {
		margin-bottom: 0%;
		width: 100%;
	}
}
.lbl-empty{
	background-color:teal;
	color:#fff;
	padding:8px 10px 8px;
	margin-top:10px;
	margin-left:2px;
	position:absolute;
	min-width:250px;
}
.xcYi3{
	width:auto;
	height:30px;
	margin:0 2px;
	padding:0 10px;
	border-radius:2px;
	border:0;
	cursor:pointer;
&:hover{
	box-shadow:2px 4px 4px #ddd;
}
&:focus{
	outline:none;
}
}
.approve-btn{
	background-color:lighten(green,5%);
	color:#fff;
}
.disapprove-btn{
	background-color:lighten(red,5%);
	color:#fff;
	
}
.view-btn{
	background-color:royalblue;
	color:#fff;
}
.gold{
	background-color:rgb(255, 123, 0);
	color:#fff;
}
.success-msg{
	height:50px;
	width:250px;
	background-color:green;
	border:1px solid green;
	color:lighten(green,80%);
	padding:10px;
	position:fixed;
	right:5px;
	margin:auto;
	border-radius:10px;
	font-weight:480;
	display:flex;
	justify-content:space-between;
	box-shadow: rgb(28 39 43 / 20%) 0px 5px 8px;
	
	#suc-cls-btn{
		font-size:1.4em;
		font-weight:500;
		cursor:pointer;
		height:25px;
		width:25px;
		border:0;
		color:lighten(green,80%);
		background-color:transparent;
		&:hover{
			cursor:pointer;
		}
	}
}
.tbl-volume{
	position:fixed;
	width:60%;
	display:flex;
	justify-content: space-between;
	bottom:10px;
	color:#333;
	background-color: #fff !important;

}
.total_volObs{
	color:#000;
}
.modal-backdrop{
	z-index: -1;
}
.modal{
	background-color:#000000a8;
	
}
.fas{
	margin:5px;

}
.modal-sm{
	max-width:400px !important;
}
.tbl-wrap-users{
	min-width:70%;
	width:calc(100% - 200px);
	overflow-x:scroll;
}
//printer
.print:last-child {
     page-break-after: auto;
}
@media print {
    html, body {
        height: 99%;    
    }
}