.intro-container{
    min-height:300px;

    background-color: $grey--eight;
    padding:30px 0 30px;
 
}
.category-box{
    width:80%;
    @include for-mobiles-only{
        width:98%;
    }
    margin:auto;
    ul{
       display:flex; 
       padding:10px;
      li{ width:calc(100% / 3.1);
        text-align:center;
        .cat-itm{
            min-height:150px;
            font-size:1.8em;
            color:$black--three;
            vertical-align: middle;
          font-weight:500;
            svg{
               max-height: 1.8em;
               max-width:1.5em;
            }
            .cat-chd{
                color:$black--two;
            }
            .count-cat{
                font-size:.4em;
            }
        }
        .cat-description{
            font-size:.5em;
        }
    }
    }
}