

.main-header-wrapper{
  height:50px;
}
.dsh_header {
  height: 50px;
  background-image: $white;
  overflow: hidden;
  border-bottom: 1px solid $grey--seven;
  position:fixed;
  background-color:$white;
  width:100vw;
}
.holderForNav {
  background-color: #ffffff44;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.041),
    rgba(193, 230, 247, 0.158)
  );
}
.navForDsh {
  height: 50px;
  width: 95vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navUlDashForLogo {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  li {
    width: calc(100% / 3);
  }
  .elLogo {
    text-align: center;
    background-color:none;
    color:midnightblue;
    font-weight:600;
    font-size: 1.2em;
    img{
      position:absolute;
      margin-top:-10px;
    }

  }
  .rightEl{
      text-align:right;
     a{
         color:$black--two;
     }
  }
}

.navUllDsh {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 40px;
}
.nvLi {
  color: $white;
  display: flex;
  align-items: center;
  width: 1.6em;
}
.nvLi-chd {
  height: 40px;
}
.nvLi figure {
  height: 40px;
  width: 40px;
  background-color: none;
  border-radius: 5px;
  margin: 0 0 0 20px;
}
.menu-bar {
  cursor: pointer;
  button {
    cursor: pointer;
    border: 0;
    background-color: inherit;
    &:focus {
      outline: none;
    }
    svg {
      stroke-width: 1em;
    }
  }
}

.menuItmNnch {
  width: 100px;
  color: #fff;
}
.menuItm {
  color: #fff;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;

  vertical-align: super;
}
.menuItm svg {
  width: 1.5em;
  height: 1.5em;
}
.menuItm:hover {
  padding: 0 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.164);
}
.mlistContainer {
  position: absolute;
  top: 0;
  background-color: #eeeeeec0;
  border-radius: 3px;
  z-index: 100;
  left: 0;
  height: calc(100vh);
  width: 100%;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  position:fixed;
  .sideForClose {
    width: 100%;
    height: 100%;
  //  background-color:$white;
    z-index:100;
  }
}
.mlistWrapper {
  background-color: $white;
  width: 300px;
  height: 100%;
  @include for-mobiles-only{
    width:50%;
  }
}

.mlistContainer dl {
}
.ddForMenu {
  margin: 0;
  width: 100%;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}
.menuItemLink {
  color: $black--two;
  font-size: 1.1em;
  width: 100%;
  display: block;
  padding: 15px 15px;
  box-sizing: border-box;
}
.menuItemLink:hover {
  color: #fff;
}
.ddForMenu:hover {
  background-image:linear-gradient(to left,lighten(midnightblue,20%),midnightblue);
  color: #fff;
}
.usr-info {
  padding: 10px 0;
}
