$gradient-banner:linear-gradient( darken($purple--three,30%),rgba(165,55,255,.5));

//#1a0835, rgba(165,55,255,.5)
.banner{
    min-height:300px;
    width:100%;
    margin:auto;
    color:$black--two;
   
    background-repeat: no-repeat;
    //background-size: cover;
    //background-image:url("../../images/banner.png");
     background-attachment: fixed;
  background-position: center;

    .banner-filter{
        height: 90vh;
        width:100%;
        background-image:$gradient-banner;
        display:flex;
        justify-content: center;
        align-items:center;
    }
    .kHnbF4{
        height:50%;
        text-align:center;

    }
    h1{
        text-align:center;
        color:$white;
        font-weight:500;
        margin:30px 0 20px 0;
    }
    h4{
        color:$blue--two;
        font-weight:400;
        padding:10px 0;
    }
    .searchBox{
        width:100%;
        height:40px;
     margin:40px 0;
       vertical-align: bottom;
    }
    .sbHold-1{
        display: flex;
        flex-wrap: nowrap;
        height:50px;
        width:85%;
        margin:auto;
        border-radius:10px;
        background-color: $white;
    }
   .searchBar{
       height:100%;
      width:calc(100% - 40px);
       margin:auto;
       box-sizing: border-box;
       border:0;
       font-size: 1em;
       font-family:inherit;
       border-top-right-radius:9999px;
        border-bottom-right-radius:9999px;

       
       &:focus{
        outline:none;
       }
   }
   .searchIcon{
       display:flex;
       justify-content: center;
       align-items: center;
       height: 45px;
       width:50px;
       svg{
        margin-top:10px;
          height:20px;
          width:20px;
       }
   }
}