.signup-form{
    width:310px;
    text-align: left;

    h3{
        height:40px;
        color:$black--two;
       // font-family: 'Montserrat';
    }
}
form{
    label{

      font-weight:500 !important;
    }
}