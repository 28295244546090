.filter-sec{
	margin:10 0px;
	box-sizing:border-box;

	.wrapper{
		width:250px !important;
		background-color:$white;
		margin-right:10px;
}

.filter-for-mini-screens{
	display:none;
	.btn{
		@include for-mobiles-only{
		background-color:$white;

	}
	}
	@include for-mobiles-only{
		display:block;

	}
}


}