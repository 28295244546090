.section-cards {
  min-height: 300px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 20px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  // overflow:auto;

  .card-items {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    transition: all 0.1s ease-in-out;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .card-self {
    border-radius: 10px;
    margin: 10px 5px;
    transition: all 0.1s ease-in-out;
  }
  .card-ul {
    height: 310px;
    width: calc(100vw / 2.2);
    max-width: 270px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 7px 0 rgb(1 1 1 / 15%);
    background-color: inherit;
    li {
      text-align: center;
      color: $black--three;

      span {
        z-index: 3;
        background-color: tomato;
        color: $white;
        padding: 0 10px;
        font-size: 0.7em;
        visibility: hidden;
      }
    }
    .li1 {
      position: relative;
      display: flex;
      width: 230px;
      justify-content: space-between;
      svg {
        // fill:;
      }
    }
    figure {
      height: 150px;
      max-width: calc(100%);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
      }
    }
    .like {
    }
    .price {
      font-size: 1.3rem;
      font-weight: 750;
      color: $black--two;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }
  }
}
.slider-btn-holder {
  height: 40px;
  min-width: 40px;


}
  .rt {
    position: absolute;
    right: 10vw;
     @include for-mobiles-only{
          right: 1%;

    }
  }
  .lft {
    position: absolute;
    left: 10vw;
     @include for-mobiles-only{
          left: 1%;

    }
  }
.scroll-btn {
  position: relative;
  width: 40px;
  z-index: 2;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  background-color:$white;
  opacity:.5;
  box-shadow: 2px 5px 7px 0 rgb(1 1 1 / 15%);
  border:0;
  &:focus{
    outline:none;
  }
  &:hover{
   background-color: $grey--seven;
  }
  svg{
    stroke:$grey--two;
    height:1.3em;
    min-width:1em;
  }
}



//AdsCard styles
.main-adsCard-wrapper{
  width:100%;
  height:170px;
background-color:$white;
margin:10px -0;
padding:10px;
border:1px solid darken($grey--eight,3%); 
.adsCard-box{
  display:flex;
 color:$black--two;
  figure{
    width:40%;
    height:150px;
    display:flex;
    align-items: center;
    object-fit: contain;
    img{
      max-height:150px;
      max-width:100%;
    }
  }
  article{
    width:60%;
    height:150px;
    padding:5px;
    display:flex;
    overflow:hidden;
  }
  }
}
.breath-padding{
  padding:0 1.5px;
}
.detailBox{
  width:80%;
}
.heart-itm{
 float:right;
 z-index:10;
  position:relative;
 // margin-left:calc(100% - 20%);
}
