.slider-wrap {
  width: 100%;
  overflow: hidden;
 display:flex;
 height:400px;
 .slider-imgHolder{
    width:100vw;
    display: flex;
    align-content: center;
  }
  figure {
    width: 100%;
    height:300px;
    overflow-y: scroll;
  scrollbar-width: none;
  object-fit: contain;

 
 &::-webkit-scrollbar{
         display:none;
    }
  }
  .slider-btn-holder{
    top:calc(300px / 2 + 25px);
  }
     
}
